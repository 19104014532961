@use '../includes' as *;

.my_list_confirm_removal_modal {
  border-radius: 6px;
  background-color: rgba($navy-blue, 0.8);
  backdrop-filter: blur(10px);
}

.my_list_confirm_removal_modal_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $g16;
  padding: $g32 $g60;
}

.my_list_confirm_removal_modal_text {
  @include body-copy-3($fog);

  max-width: 280px;
  font-weight: $bold;
  text-align: center;
}

.my_list_confirm_removal_modal_buttons {
  display: flex;
  justify-content: center;
  gap: $g16;
}

// When this modal is open we hide all kabob menus
body:has(.my_list_confirm_removal_modal[open]) {
  [class*='kabob_menu_tippy'] {
    display: none;
  }
}
